import Vue from 'vue'
import App from './App.vue'
import router from './router'
Vue.config.productionTip = false
import Api from './api/Api.js'
import locales from './plugins/vant.js'
import 'font-awesome/css/font-awesome.css';
import CM from './util/common.js'
import i18n from '@/lang'
Vue.use(CM);
Vue.prototype.CM = CM;
Vue.use(Api);
Vue.prototype._Api = Api;
Vue.prototype.$Local = locales;
new Vue({
  data: {
    eventHub: new Vue()  
  },
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
console.log = function(){
  
};

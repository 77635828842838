export default {
  common:{
    back:"Back",
    home:"Home"
  },
  memu:{
    sellout:"Sold Out",
    qtyBounds:"Servings",
    orderlist:"My Oder",
    ordernum:"Total number",
    orderhistory:"Order history",
    pay:"Check Out",
    seecart:"My Cart",
    cartnull:"No dishes in your cart",
    confirmorder:"Confirm order for all items in cart?",
    ordersuccess:"Thank you for your order, please wait while we serve your food.",
    clearcart:'Are you sure you want to remove all items in the cart?',
    cartfoods:"Foods in cart",
    clearbtn:"Clear",
    stockleft:"Only",
    leftnumber:"left",
    cartTotal:" items total amount ",
    confirmcart:"Submit Order",
    qrtitle:"QR code for bill",
    qrmsg:"Please present this QR code to the cashier",
    deleteFood:'Are you sure to delete the product?',
    addincart:"Add to card",
    inventoryShortage:"Inventory shortage",
    checktotalnum:",Check the quantity you have selected and the quantity in your cart",
    changelanhint:"Are you sure to switch languages? The menu is reloaded and the cart items are cleared.",
    totalAmount:"Total amount",
    ordertime:"Order time",
    notchoosed:"not chooesd",
    atleastmae:"Choose at least ",
    atleastn: "items",
    havepaid:"The page has expired. If you wish to use it again, please open the camera and scan the QR code again.",
    noparams:"Parameter is incorrect. If you wish to use it again, please open the camera and scan the QR code again.",
    nodata: "No data",
    zeilitsu:"Consumption Tax",
    call:"Call Staff",
    callfail:"Sorry, the call failed.",
    callmsg:"Please try pressing the [Call Staff] button again.",
    callsuccess:"The staff has been called, please wait a moment.",
    callattention:"Do you want to call a staff member to your seat?",
    unitprice:"Unit price",
    addcartsuccess:"Added to cart.",
    recommentmsg:"This is recommended dishes.\n Would you like this as well?",
    dislike:"Skip and Submit Order"
  }
}

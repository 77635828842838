//接口请求域名
var API_URL = "";
if(process.env.NODE_ENV == 'development'){
	API_URL = "api/";
}else{
	// API_URL = "https://sit-api.smartwe.jp/";//测试
	API_URL = "https://api.smartwe.jp/";//生产
}
//接口地址
export default{
		getindex:API_URL + "web/mobile/index/v1",//菜单列表
		mobileorder:API_URL + "web/mobile/v2/order",//下单
		getOrderDetails:API_URL + "web/mobile/order/detail",//订单详情
		checkstock:API_URL + "web/mobile/stock-booking",//检测库存
		callstuff:API_URL + "web/mobile/call",//呼出
}

export default {
  common:{
    back:"返回",
    home:"首页"
  },
  memu:{
    sellout:"售罄",
    qtyBounds:"份限定",
    orderlist:"订单·结账",
    ordernum:"总份数",
    orderhistory:"订单明细",
    pay:"结账",
    seecart:"查看购物车",
    cartnull:"您的购物车目前没有菜品",
    confirmorder:"确认提交订单吗？",
    ordersuccess:"下单成功，您的菜品即将到达，请稍等",
    clearcart:'确定清空购物车吗',
    cartfoods:"购物车的菜品",
    clearbtn:"清空",
    stockleft:"剩余",
    leftnumber:"份",
    cartTotal:"份合计金额",
    confirmcart:"确认下单",
    qrtitle:"结账二维码",
    qrmsg:"请前往前台扫码此二维码结账，谢谢。",
    deleteFood:'确定删除此商品吗？',
    addincart:"加入购物车",
    inventoryShortage:"库存不足",
    checktotalnum:"，请检查您选择的数量和购物车中的数量",
    changelanhint:"确认切换语言吗，将会清空购物车并重新加载菜单",
    totalAmount:"总金额",
    ordertime:"下单日",
    notchoosed:"未选择",
    atleastmae:"请最少选择 ",
    atleastn: "个选项",
    havepaid:"页面已过期。如果想再次使用，请打开相机并重新扫描点餐二维码。",
    noparams:"参数错误。如果想再次使用，请打开相机并重新扫描QR码。",
    nodata: "暂无数据",
    zeilitsu:"消费税",
    call:"呼叫",
    callfail:"抱歉呼叫失败",
    callmsg:"请尝试重新按呼出按钮",
    callsuccess:"已呼叫店员，请稍等。",
    callattention:"确认要呼叫店员到您的座位来吗？",
    unitprice:"单价",
    addcartsuccess:"已加入购物车",
    recommentmsg:"店铺推荐菜品，要不要一起点？",
    dislike:"跳过并下单"
  }
}
